import moment from 'moment';
import Vue from 'vue';
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    namespaced: true,
    state: {
        user: null,

        roller: [
            { id: 'FRIVILLIG', navn: 'Frivillig', flertall: 'Frivillige', foreldre: null },
            { id: 'FRIVILLIG_SORG', navn: 'Frivillig sorgtjenesten', flertall: 'Frivillige sorgtjenesten', kortNavn: 'Sorgtjenesten', foreldre: 'FRIVILLIG' },
            {
                id: 'FRIVILLIG_HJEMME',
                navn: 'Frivillig hjemmetjeneste',
                flertall: 'Frivillige hjemmetjeneste',
                kortNavn: 'Hjemmetjenesten',
                foreldre: 'FRIVILLIG',
            },
            {
                id: 'FRIVILLIG_MOTESTED',
                navn: 'Frivillig Møtested Oslo Øst',
                flertall: 'Frivillig Møtested Oslo Øst',
                kortNavn: 'Møtested Oslo Øst',
                foreldre: 'FRIVILLIG',
            },
            { id: 'BRUKER', navn: 'Bruker', flertall: 'Brukere', foreldre: null },
            { id: 'BRUKER_SORG', navn: 'Bruker sorgtjenesten', flertall: 'Brukere sorgtjenesten', kortNavn: 'Sorgtjenesten', foreldre: 'BRUKER' },
            { id: 'BRUKER_HJEMME', navn: 'Bruker hjemmetjeneste', flertall: 'Brukere hjemmetjenesten', kortNavn: 'Hjemmetjeneste', foreldre: 'BRUKER' },
            {
                id: 'BRUKER_MOTESTED',
                navn: 'Bruker Møtested Oslo Øst',
                flertall: 'Brukere Møtested Oslo Øst',
                kortNavn: 'Møtested Oslo Øst',
                foreldre: 'BRUKER',
            },
            { id: 'FRIVILLIGIPROSESS', navn: 'Frivillig i prosess', flertall: 'Frivillige i prosess', foreldre: null },
            { id: 'ANSATT', navn: 'Ansatt', flertall: 'Ansatte', foreldre: null },
            { id: 'KONTAKT', navn: 'Kontakt', flertall: 'Kontakter', foreldre: null },
        ],

        statuser: [
            { id: 'AKTIV', navn: 'Aktiv' },
            { id: 'NY', navn: 'Ny' },
            { id: 'PERMISJON', navn: 'I permisjon' },
            { id: 'SLUTTET', navn: 'Sluttet' },
        ],

        statusNavn: {
            AKTIV: 'Aktiv',
            NY: 'Ny',
            PERMISJON: 'I permisjon',
            SLUTTET: 'Sluttet',
        },

        sorgRapporter: [],
        hjemmeRapporter: [],
    },
    mutations: {
        user(state, user) {
            state.user = user;
            state.userRoles = [];
            for (const role of user.roller) {
                state.userRoles.push(role.id);
            }
        },
        sorgRapporter(state, sorgRapporter) {
            state.sorgRapporter = sorgRapporter;
        },
        hjemmeRapporter(state, hjemmeRapporter) {
            state.hjemmeRapporter = hjemmeRapporter;
        },
    },
    actions: {
        me: async function (context) {
            let response = await context.dispatch('request', {
                url: '/person/meg',
                method: 'get',
            });
            if (response) {
                const user = await context.dispatch('formatPerson', response);
                context.commit('user', user);

                if (context.state.userRoles.indexOf('FRIVILLIG_SORG') >= 0) {
                    response = await context.dispatch('request', {
                        url: '/sorggruppe/rapportskjema/frivillig/' + user.id,
                        method: 'get',
                    });

                    const sorgRapporter = [];
                    if (response && Array.isArray(response)) {
                        for (const rapport of response) {
                            sorgRapporter.push(await await context.dispatch('formatGriefGroupReport', rapport));
                        }
                    }

                    context.commit('sorgRapporter', sorgRapporter);
                }

                if (context.state.userRoles.indexOf('FRIVILLIG_HJEMME') >= 0) {
                    response = await context.dispatch('request', {
                        url: '/hjemmetjeneste/rapportskjema/frivillig/' + user.id,
                        method: 'get',
                    });

                    const hjemmeRapporter = [];
                    if (response && Array.isArray(response)) {
                        for (const rapport of response) {
                            hjemmeRapporter.push(await await context.dispatch('formatVisitReport', rapport));
                        }
                    }

                    context.commit('hjemmeRapporter', hjemmeRapporter);
                }
            }
        },

        request: async function (context, params) {
            try {
                const token = context.rootGetters['authenticate/accessToken'];
                if (token) {
                    const response = await Vue.axios({
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                        method: params.method,
                        url: params.url,
                        data: params.data ? params.data : {},
                        params: params.params ? params.params : {},
                        responseType: params.responseType ? params.responseType : 'json',
                    });

                    if (response.headers && typeof response.headers['content-type'] != 'undefined' && response.headers['content-type'].indexOf('image') === 0) {
                        return response;
                    } else if (response.data) {
                        return response.data;
                    } else {
                        return true;
                    }
                }
            } catch (e) {
                /*if (e.message == 'Network Error') {
                    const expires = context.rootGetters['authenticate/accessTokenExpires'];
                    console.log('expired:' + expires.format('YYYY-MM-DD HH:mm:ss'));
                }*/
                let error = '';
                if (e.response) {
                    if (e.response.data.error && e.response.data.status) {
                        error = e.response.data.error + ' (' + e.response.data.status + ')';
                    }
                }
                if (params.responseType != 'arraybuffer') {
                    await context.dispatch('snackbar', 'Det oppstod en feil. Prøv igjen.' + (error ? '<br>' + error : ''), { root: true });
                    console.log(e);
                }
                return e;
            }
        },

        imageRequest: async function (context, params) {
            if (!params) {
                params = {};
            }
            params.responseType = 'arraybuffer';

            try {
                const response = await context.dispatch('request', params);

                if (response.data) {
                    return (
                        'data:' +
                        response.headers['content-type'] +
                        ';base64,' +
                        btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
                    );
                }
            } catch (e) {
                console.log(e);
            }
        },

        attachmentRequest: async function (context, params) {
            const response = await context.dispatch('request', {
                method: 'get',
                responseType: 'blob',
                url: params.url,
            });

            if (typeof response == 'object' && response.constructor.name == 'Blob') {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', params.filename);
                document.body.appendChild(link);
                link.click();
            }
        },

        updateReports: function (context) {
            const sorgRapporter = [];
            if (context.state.sorgRapporter) {
                for (const rapport of context.state.sorgRapporter) {
                    if (!rapport.levert) {
                        sorgRapporter.push(rapport);
                    }
                }
            }
            context.commit('sorgRapporter', sorgRapporter);

            const hjemmeRapporter = [];
            if (context.state.hjemmeRapporter) {
                for (const rapport of context.state.hjemmeRapporter) {
                    if (!rapport.levert) {
                        hjemmeRapporter.push(rapport);
                    }
                }
            }
            context.commit('hjemmeRapporter', hjemmeRapporter);
        },

        exportToExcel: function (context, params) {
            const filename = 'Kontaktinformasjon';
            const ws = XLSX.utils.aoa_to_sheet(params.table, { dateNF: 'YYYY-MM-DD HH:mm:ss' });
            ws['!cols'] = params.wscols;

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, params.filename);
            XLSX.writeFile(wb, filename + '.xlsx');
        },

        formatPerson: function (context, value) {
            const person = Object.assign({}, value);
            person.fulltNavn = (person.fornavn + ' ' + person.etternavn).trim();
            person.sokeString = person.fulltNavn.toLowerCase();
            if (person.lopenummer) {
                person.sokeString += ' ' + person.lopenummer;
            }

            person.statusNavn = context.state.statusNavn[person.status];

            /* mangler data fra API:
            const tjenester = [];
            if (person.roller) {
                for (const rolle of person.roller) {
                    if (rolle.id == 'FRIVILLIG_SORG') {
                        tjenester.push('Sorgtjenesten');
                        console.log(rolle.id);
                    } else if (rolle.id == 'FRIVILLIG_HJEMME') {
                        tjenester.push('Hjemmetjeneste');
                    }
                }
            }
            person.tjenester = tjenester.sort().join(', ');
            */

            for (const field of ['foedselsdato', 'sluttet', 'opprettet', 'oppstartsdato']) {
                if (person[field]) {
                    person[field] = moment(person[field], 'DD.MM.YYYY');
                } else {
                    person[field] = null;
                }
            }

            if (person.permisjoner) {
                for (const permisjon of person.permisjoner) {
                    permisjon.fra = permisjon.fra ? moment(permisjon.fra, 'DD.MM.YYYY') : null;
                    permisjon.til = permisjon.til ? moment(permisjon.til, 'DD.MM.YYYY') : null;
                }
            }

            if (person.kommentarer) {
                for (const kommentar of person.kommentarer) {
                    kommentar.opprettet = kommentar.opprettet ? moment(kommentar.opprettet, 'DD.MM.YYYY HH:mm:ss') : null;
                }
            }

            if (person.samtykke) {
                for (const field of ['dato']) {
                    if (person.samtykke[field]) {
                        person.samtykke[field] = moment(person.samtykke[field], 'DD.MM.YYYY');
                    }
                }
            }

            if (person.sorginformasjon) {
                for (const field of ['tapsdato', 'forstegangssamtaleDato', 'sendtSMSDato']) {
                    if (person.sorginformasjon[field]) {
                        person.sorginformasjon[field] = moment(person.sorginformasjon[field], 'DD.MM.YYYY');
                    }
                }
            }

            if (person.hjemmeinformasjon) {
                for (const field of ['forstegangssamtaleDato']) {
                    if (person.hjemmeinformasjon[field]) {
                        person.hjemmeinformasjon[field] = moment(person.hjemmeinformasjon[field], 'DD.MM.YYYY');
                    }
                }
            }

            if (person.frivilliginformasjon) {
                for (const field of ['kontaktDato', 'inntakssamtaleDato', 'samtykkeskjemaDato', 'politiattestDato']) {
                    if (person.frivilliginformasjon[field]) {
                        person.frivilliginformasjon[field] = moment(person.frivilliginformasjon[field], 'DD.MM.YYYY');
                    }
                }
            }

            if (person.undervisning) {
                for (const undervisning of person.undervisning) {
                    for (const field of ['dato', 'opprettet', 'endret']) {
                        if (undervisning[field]) {
                            undervisning[field] = moment(undervisning[field], 'DD.MM.YYYY');
                        }
                    }
                }

                person.undervisning.sort(function (a, b) {
                    if (a.dato && b.dato) {
                        return b.dato.valueOf() - a.dato.valueOf();
                    } else if (!a.dato) {
                        return -1;
                    } else if (!b.dato) {
                        return 1;
                    }
                });
            }

            /*if (person.foedselsdato) {
                person.foedselsdato = moment(person.foedselsdato, 'dd.MM.yyyy');
            }*/

            return person;
        },

        formatGruppe: function (context, value) {
            const gruppe = Object.assign({}, value);
            return gruppe;
        },

        formatGriefGroup: async function (context, value) {
            const gruppe = Object.assign({}, value);

            for (const field of ['oppstart', 'avsluttet']) {
                if (gruppe[field]) {
                    gruppe[field] = moment(gruppe[field], 'DD.MM.YYYY');
                } else {
                    gruppe[field] = null;
                }
            }

            if (gruppe.deltakere) {
                for (const deltaker of gruppe.deltakere) {
                    for (const field of ['lagtTil', 'fjernet']) {
                        if (deltaker[field]) {
                            deltaker[field] = moment(deltaker[field], 'DD.MM.YYYY HH:mm:ss');
                        }
                    }
                    deltaker.person = await context.dispatch('formatPerson', deltaker.person);
                }
            }

            if (gruppe.ledere) {
                for (const leder of gruppe.ledere) {
                    for (const field of ['lagtTil', 'fjernet']) {
                        if (leder[field]) {
                            leder[field] = moment(leder[field], 'DD.MM.YYYY HH:mm:ss');
                        }
                    }
                    leder.person = await context.dispatch('formatPerson', leder.person);
                }
            }

            if (gruppe.kommentarer) {
                for (const kommentar of gruppe.kommentarer) {
                    kommentar.opprettet = kommentar.opprettet ? moment(kommentar.opprettet, 'DD.MM.YYYY HH:mm:ss') : null;
                }
            }
            return gruppe;
        },

        formatGriefGroupReport: async function (context, value) {
            const report = Object.assign({}, value);

            if (report.gruppe) {
                report.gruppe = await context.dispatch('formatGriefGroup', report.gruppe);
            }

            if (report.frivillig) {
                report.frivillig = await context.dispatch('formatPerson', report.frivillig);
            }

            for (const field of ['dato', 'levert', 'levert']) {
                if (report[field]) {
                    report[field] = moment(report[field], 'DD.MM.YYYY');
                } else {
                    report[field] = null;
                }
            }

            return report;
        },

        formatVisit: async function (context, value) {
            const visit = Object.assign({}, value);

            for (const field of ['opprettet', 'avsluttet']) {
                if (visit[field]) {
                    visit[field] = moment(visit[field], 'DD.MM.YYYY');
                } else {
                    visit[field] = null;
                }
            }
            if (visit.bruker) {
                visit.bruker = await context.dispatch('formatPerson', visit.bruker);
            }
            if (visit.frivillig) {
                visit.frivillig = await context.dispatch('formatPerson', visit.frivillig);
            }

            return visit;
        },

        formatVisitReport: async function (context, value) {
            const report = Object.assign({}, value);

            if (report.frivillig) {
                report.frivillig = await context.dispatch('formatPerson', report.frivillig);
            }

            for (const field of ['dato', 'levert']) {
                if (report[field]) {
                    report[field] = moment(report[field], 'DD.MM.YYYY');
                } else {
                    report[field] = null;
                }
            }

            return report;
        },

        formatActivity: async function (context, value) {
            const aktivitet = Object.assign({}, value);

            for (const field of ['start', 'slutt', 'publisert', 'paameldingsfrist']) {
                if (aktivitet[field]) {
                    aktivitet[field] = moment(aktivitet[field], 'DD.MM.YYYY HH:mm:ss');
                }
            }

            if (aktivitet.deltakere) {
                for (const deltaker of aktivitet.deltakere) {
                    for (const field of ['lagtTil', 'fjernet']) {
                        if (deltaker[field]) {
                            deltaker[field] = moment(deltaker[field], 'DD.MM.YYYY HH:mm:ss');
                        }
                    }
                    deltaker.person = await context.dispatch('formatPerson', deltaker.person);
                }
            }
            return aktivitet;
        },

        formatArticle: async function (context, value) {
            const artikkel = Object.assign({}, value);

            for (const field of ['publisert']) {
                if (artikkel[field]) {
                    artikkel[field] = moment(artikkel[field], 'DD.MM.YYYY HH:mm:ss');
                }
            }

            return artikkel;
        },

        formatMaalgrupppe: function (context, maalgrupper) {
            if (maalgrupper && maalgrupper.length) {
                let maalgruppper = [];
                for (const maalgruppe of maalgrupper) {
                    const rolle = (maalgruppe.rolle ? context.getters.rolle(maalgruppe.rolle.id).flertall : 'alle').toLowerCase();
                    switch (maalgruppe.status) {
                        case 'AKTIV':
                            maalgruppper.push('aktive ' + rolle);
                            break;
                        case 'NY':
                            maalgruppper.push('nye ' + rolle);
                            break;
                        case 'PERMISJON':
                            maalgruppper.push(rolle + ' i permisjon');
                            break;
                        case 'SLUTTET':
                            maalgruppper.push(rolle + ' som har sluttet');
                            break;
                        default:
                            maalgruppper.push('alle ' + rolle);
                    }
                }
                maalgruppper = maalgruppper.join(', ');
                const lastIndex = maalgruppper.lastIndexOf(', ');
                if (lastIndex != -1) {
                    maalgruppper = maalgruppper.substring(0, lastIndex) + ' og' + maalgruppper.substring(lastIndex + 1);
                }
                return 'For ' + maalgruppper + '.';
            }
            return 'For alle.';
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        userRoles(state) {
            return state.userRoles;
        },

        hasUserRole: (state) => (role) => {
            return state.userRoles ? state.userRoles.indexOf(role) != -1 : false;
        },

        hasRole: (state) => (role) => {
            return state.userRoles ? state.userRoles.indexOf(role) != -1 : false;
        },

        hasFullAccessGriefGroup: (state) => (/*group*/) => {
            if (state.userRoles && state.userRoles.indexOf('ANSATT') != -1) {
                return true;
            } else if (
                //state.user.id == '0426c51e-ef4e-403f-90f8-7907a6eb19ac' || // Kristine Steensen
                state.user.id == 'b849e7c0-cf91-4633-a096-c0667d67eb9d' || // Trine Johansen
                state.user.id == '53bef551-6f5f-42d8-8043-011d5c17e865' // Kristine Lauve Steensen
            ) {
                return true;
            }
            return false;
        },

        maalgrupper(state) {
            const maalgrupper = [];
            if (state.user) {
                const status = state.user.status;
                if (state.user.roller) {
                    for (const rolle of state.user.roller) {
                        maalgrupper.push({ rolle: { id: rolle.id }, status: status });
                    }
                }
            }
            return maalgrupper;
        },

        roller(state) {
            return state.roller;
        },

        rolle: (state) => (id) => {
            for (const rolle of state.roller) {
                if (rolle.id == id) {
                    return rolle;
                }
            }
        },

        statuser(state) {
            return state.statuser;
        },

        sorgRapporter(state) {
            return state.sorgRapporter;
        },
        hjemmeRapporter(state) {
            return state.hjemmeRapporter;
        },
    },
};
